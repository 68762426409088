import React from 'react'
import tw from 'twin.macro'

import { InputLabel } from './InputLabel'

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  value?: string
  onTextChange: (val: string) => void
  name: string
  label?: string
  placeholder?: string
  rows?: number
}

export const MultiLineTextInput: React.FC<Props> = ({
  value,
  onTextChange,
  name,
  label,
  placeholder,
  rows,
  ...inputProps
}) => (
  <div tw="flex flex-col gap-1">
    {label && <InputLabel htmlFor={name} label={label} />}
    <textarea
      {...inputProps}
      name={name}
      id={name}
      tw="shadow-sm focus:(ring-primary-500 border-primary-500) block w-full sm:text-sm border-gray-200 rounded-md"
      placeholder={placeholder}
      rows={rows || 4}
      value={value}
      onChange={(x) => onTextChange(x.currentTarget.value)}
    />
  </div>
)
