import Link from 'next/link'
import { useRouter } from 'next/router'
import { ClientSafeProvider } from 'next-auth/react'
import React, { FC, Fragment, useContext, useState } from 'react'
import tw from 'twin.macro'

import { EditChallengeContext } from '@/domain/challenge/EditChallengeContext'
import { useProviders } from '@/domain/common/useProviders'
import { useSignIn } from '@/domain/common/useSignIn'
import { PROVIDER_BUTTONS } from '@/frontend/common/components/LoginButtons'

import { DESC_PLACEHOLDER } from '../domain/common/const_str'
import { ChallengeTypeSelect } from '../frontend/common/components/ChallengeTypeSelect'
import { GymSearch } from '../frontend/common/components/GymSearch'
import { MultiLineTextInput } from '../frontend/common/components/MultiLineTextInput'
import { TextInput } from '../frontend/common/components/TextInput'
import Auth from '../layouts/auth'
import type { NextPage } from '../types/next'

const Home: NextPage = () => {
  const router = useRouter()
  const providers = useProviders()
  const [challenge, dispatch] = useContext(EditChallengeContext)

  const href = '/challenge/new'

  return (
    <main tw="min-w-full flex items-center flex-col pb-8 sm:(pt-8) gap-10">
      <div tw="flex flex-wrap gap-8 justify-center w-full">
        <div tw="prose prose-sm max-w-sm relative">
          <div
            className="pattern-dots-lg"
            tw="h-36 w-36 absolute -top-4 -left-4 sm:(-top-8 -left-9) text-pink-400"
          />
          <div tw="bg-white relative p-4">
            <h1>
              Turn your gym into a community,{' '}
              <span tw="text-primary-600">with fitness challenges.</span>
            </h1>
            <p tw="text-lg">
              We make it super easy to create monthly, weekly or WOD fitness challenges.
            </p>
            <p tw="text-lg">
              Members can engage around leaderboards to compete with others and, importantly,
              themselves!
            </p>
          </div>
        </div>
        <form
          tw="max-w-sm w-full sm:w-auto grid grid-flow-row gap-4 prose pt-4"
          onSubmit={(e) => {
            router.push(href)
            e.preventDefault()
          }}
        >
          <h2 tw={'mb-0!'}>Create a challenge now</h2>
          <ChallengeTypeSelect
            // @ts-expect-error(arlyon): score type is just a string on the backend
            value={challenge.scoreType}
            onChange={(e) => dispatch({ type: 'update', payload: { scoreType: e } })}
          />
          {/* TODO(future): Add back support for series */}
          {/*<FrequencySelect*/}
          {/*  value={challenge.frequency}*/}
          {/*  onChange={(e) => dispatch({ type: 'update', payload: { frequency: e } })}*/}
          {/*/>*/}
          <TextInput
            onChange={(value) => dispatch({ type: 'update', payload: { title: value } })}
            name={'title'}
            value={challenge.title}
            placeholder="Challenge name"
          />
          <MultiLineTextInput
            onTextChange={(value) => dispatch({ type: 'update', payload: { description: value } })}
            name={'description'}
            value={challenge.description}
            placeholder={DESC_PLACEHOLDER}
            onKeyDown={(e) => e.ctrlKey && e.key === 'Enter' && router.push(href)}
          />
          <Link href={href} passHref={true}>
            <button
              type="button"
              tw="block w-full cursor-pointer py-3 px-4 rounded-md shadow bg-gradient-to-r text-center from-green-500 to-green-600 text-white font-medium hover:from-green-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 focus:ring-offset-green-900"
            >
              Add more details
            </button>
          </Link>
        </form>
      </div>
      <section tw="flex justify-center p-10 flex-col gap-6 items-center">
        <h1 tw="text-2xl font-bold">Sounds good? Log in below!</h1>
        <div tw="flex gap-4 flex-wrap justify-center">
          {Object.entries(providers ?? {})
            .filter(([p]) => p in PROVIDER_BUTTONS)
            .map(
              ([p, v]) =>
                [PROVIDER_BUTTONS[p as keyof typeof PROVIDER_BUTTONS], v] as [
                  FC,
                  ClientSafeProvider,
                ],
            )
            .map(([Button, props], index) => (
              <Button key={index} {...props} />
            ))}
        </div>
      </section>
    </main>
  )
}

Home.getLayout = function Layout(page) {
  return (
    <Auth name="Find your community" headerItem={<GymSearch />}>
      {page}
    </Auth>
  )
}

export default Home
